import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  patient: [],
};

export const patientSlice = createSlice({
  name: "patientInfo/patient",
  initialState,
  reducers: {
    setPatientData: (_, action) => action.payload,
  },
});

export const { setPatientData } = patientSlice.actions;

export default patientSlice.reducer;
